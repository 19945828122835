<template>
  <div id="regist">
    <div class="regist_in">
      <nav-tab-part1 pagetitle="注册账号">
        <template v-slot:topRightText><span>我已注册，<span style="color: #3a86ff;"  @click="login">点击登录</span></span></template>
      </nav-tab-part1>
      <div class="step">
        <div class="step_in">
          <el-steps :active="step_active" align-center>
            <el-step title="填写账号信息" description=""></el-step>
            <!-- <el-step title="填写企业信息" description=""></el-step> -->
            <el-step title="注册成功" description=""></el-step>
          </el-steps>
        </div>
      </div>
      <div class="info_form" v-if="step_active == 1">
        <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="手机号码" prop="phone">
            <el-input
              type="tel"
              maxlength="11"
              v-model.number="ruleForm.phone"
            ></el-input>
          </el-form-item>
          <el-form-item label="短信验证" prop="code">
            <el-input
            class="pcstyle"
              type="text"
              maxlength="6"
              v-model="ruleForm.code"
            ></el-input>
            <p class="getcode" @click="getcode" v-show="!iscode" style="position: absolute; right: 0; top: 0">
              获取验证码
            </p>
            <p class="getcodes" v-show="iscode" style="position: absolute;right: 0;top: 0;">{{ secode }}秒后重新获取</p>
          </el-form-item>
          <el-form-item label="设置密码" prop="pass">
            <el-input
              type="password"
              v-model="ruleForm.pass"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="checkPass">
            <el-input
              type="password"
              v-model="ruleForm.checkPass"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <div class="operationBtn">
            <el-button type="primary" @click="submitForm('ruleForm')"
              >注册</el-button
            >
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </div>
        </el-form>
      </div>

      <!-- <div class="compangyinfo_form" v-if="step_active == 2">

        <el-form
          label-position="left"
          :model="ruleForm_confirmCompany"
          status-icon
          :rules="rules"
          ref="ruleForm_confirmCompany"
          label-width="100px"
          class="demo-ruleForm"
        >
          <div class="confComp_section1">
            <el-form-item label="企业名称" prop="company_name">
              <el-input
                type="text"
                v-model="ruleForm_confirmCompany.company_name"
                placeholder="例:xxx有限公司"
              ></el-input>
            </el-form-item>

            <el-form-item label="企业类型" prop="company_type">
              <el-select
                v-model="ruleForm_confirmCompany.company_type"
                placeholder="请选择企业类型"
              >
                <el-option
                  v-for="(item, index) in typeList"
                  :key="index"
                  :label="item.DictName"
                  :value="item.DictID"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="企业规模" prop="company_size">
              <el-select
                v-model="ruleForm_confirmCompany.company_size"
                placeholder="请选择企业类型"
              >
                <el-option
                  v-for="(item, index) in sizeList"
                  :key="index"
                  :label="item.DictName"
                  :value="item.DictID"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="法人姓名" prop="ArtificialPersonName">
              <el-input
                type="text"
                v-model="ruleForm_confirmCompany.ArtificialPersonName"
                placeholder="例:xxx有限公司"
              ></el-input>
            </el-form-item>
            <el-form-item label="税号" prop="TaxpayerIdentityNumber">
              <el-input
                type="text"
                v-model="ruleForm_confirmCompany.TaxpayerIdentityNumber"
                autocomplete="off"
                placeholder="即统一社会信用代码"
              ></el-input>
            </el-form-item>
            <el-form-item label="注册日期" prop="createDate">
              <el-date-picker
                type="date"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
                placeholder="选择日期"
                v-model="ruleForm_confirmCompany.createDate"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
            <el-form-item  label="街道/园区"  prop="streetPark">
              <el-select v-model="ruleForm_confirmCompany.streetPark" 
              filterable 
              remote
              :remote-method="toSearchRList"
              placeholder="请输入街道/园区关键词"
              :loading="loading"
              v-el-select-loadmore="loadmore"
              >
                <el-option
                  v-for="(item,index) in streetParkList"
                  :key="index"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="confComp_section2">
            <el-form-item label="注册省/市/区" prop="selectedOptions">
              <el-cascader
                :options="regionData"
                v-model="ruleForm_confirmCompany.selectedOptions"
                separator=" - "
                @change="handleChange"
                clearable
              ></el-cascader>
            </el-form-item>

            <el-form-item label="注册详细地址" prop="regAddressExtend">
              <el-input
                type="text"
                v-model="ruleForm_confirmCompany.regAddressExtend"
                placeholder="请输入注册详细地址"
              ></el-input>
            </el-form-item>
            <el-form-item label="办公省/市/区">
              <el-cascader
                :options="regionData"
                v-model="selectedOptions1"
                separator=" - "
                @change="handleChange1"
                clearable
              ></el-cascader>
            </el-form-item>
            <el-form-item label="办公详细地址" prop="workAddressExtend">
              <el-input
                type="text"
                v-model="ruleForm_confirmCompany.workAddressExtend"
                placeholder="办公详细地址"
              ></el-input>
            </el-form-item>

            <el-form-item label="经营范围" prop="BusinessScope">
              <el-input
                type="text"
                v-model="ruleForm_confirmCompany.BusinessScope"
                placeholder="企业经营范围"
              ></el-input>
            </el-form-item>

            <el-form-item
              label="营业执照"
              prop="BusinessLicenseUrl"
              class="uploadfile"
            >
              <el-upload
                :action="apiBaseUrl+'/API/UploadFiles'"
                list-type="picture-card"
                :data="imguploaddata"
                :on-success="uploadfile"
                :limit="1"
                ref="upload"
                :on-exceed="exceedimg"
              >
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{ file }">
                  <img
                    class="el-upload-list__item-thumbnail"
                    :src="file.url"
                    alt=""
                  />
                  <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>

                    <span
                      v-if="!disabled"
                      class="el-upload-list__item-delete"
                      @click="handleRemove(file)"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
              <el-dialog :modal="false" :visible.sync="dialogVisible">
                <img class="imgclass" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>
          </div>

          <div
            class="operationBtn"
          >
            <el-button
              class="btn1"
              @click="sumbit_CompanyCertification('ruleForm_confirmCompany')"
              >提交认证</el-button
            >
            <el-button @click="resetForm('ruleForm_confirmCompany')"
              >重置</el-button
            >
            <el-button @click="step_active += 1">跳过认证</el-button>
          </div>
        </el-form>

        <div class="operationBtn"></div>
      </div> -->

      <div class="registsuccess" v-if="step_active == 3">
        <div class="successimg">
          <img src="~/assets/images/login/success.png" alt="404" />
        </div>
        <div class="registsuccess_tipinfo">
          <p>您已成功注册数字矩阵账号，祝您生活愉快~~~</p>
        </div>
        <div class="loginbtn">
          <el-button @click="login">立即登录</el-button>
        </div>
      </div>
      <!-- <regist-footer></regist-footer> -->
    </div>
  </div>
</template>

<script>
import navTabPart1 from '@/components/common/nav/navTab_part1.vue'
import registFooter from "@/components/content/login/registFooter.vue";
import { regionData, CodeToText } from "element-china-area-data";
export default {
  name: "Register",
  directives:{
    'el-select-loadmore':{
      bind(el,binding){
        const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap')
        console.log(SELECTWRAP_DOM)
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
          if(condition){
            binding.value()
          }
        }) 
      }
    }
  },
  data() {
    //注册校验
    const validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("手机号不可为空"));
      } else {
        if (value !== "") {
          var reg = /^1[3456789]\d{9}$/;
          if (!reg.test(value)) {
            callback(new Error("请输入有效的手机号码"));
          }
        }
        callback();
      }
    };
    const validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("验证码不可为空"));
      } else {
        callback();
      }
    };

    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (value.length < 6) {
        callback(new Error("密码长度最少6位"));
      } else if (value.length > 16) {
        callback(new Error("密码长度最多16位"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    const validataTaxpayerIdentityNumber = (rule,value,callback) => {
      const TaxpayerIdentityNumberRule = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/;//15/18位
      console.log(this.ruleForm_confirmCompany.TaxpayerIdentityNumber)
      if(!TaxpayerIdentityNumberRule.test(this.ruleForm_confirmCompany.TaxpayerIdentityNumber)){
        callback(new Error("请输入合法税号"))
      }
      else{
        callback() 
      }
    }
    return {
      iscode:false,
      secode:60,
      codetime: null,
      InputRecommended: "", //	推荐用户邀请码
      apiBaseUrl:window.sessionStorage.getItem('apiBaseUrl'),//文件上传接口请求前缀
      //注册信息表单
      ruleForm: {
        phone: "",
        code: "",
        pass: "",
        checkPass: "",
      },
      //禁用选择未来日期
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      userinfo: "", //注册后返回得用户信息
      //公司认证信息表单
      ruleForm_confirmCompany: {
        company_name: "", //公司名称
        company_type: "", //公司类型
        company_size: "", //公司规模
        ArtificialPersonName: "", //法人姓名
        TaxpayerIdentityNumber: "", //税号
        createDate: "", //注册日期
        regAddressID: "", //注册省市区code
        regAddressExtend: "", //注册详细地址
        workAddressID: "", //工作 省市区code
        workAddressExtend: "", //工作 详细地址
        BusinessScope: "", //经营范围
        BusinessLicenseUrl: "", //营业执照url
        streetPark:"",    //街道园区
        selectedOptions: [], //注册省市区--显示
      },
      typeList: [], //企业类型列表
      sizeList: [], //企业规模列表
      regionData: [],
    
      selectedOptions1: [], //办公省市区--显示

      streetParkList: [],//园区/街道
      pageIndex:1,//页码
      pageSize:10,//页面容量
      loading:false,//是否正在从远程获取数据
      searchvalue:'',//搜索街道/园区的搜索值

      rules: {
        //注册信息验证
        phone: [{ validator: validatePhone, trigger: "blur" }],
        code: [{ validator: validateCode, trigger: "blur" }],
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
        //公司认证信息验证validator: validateComName
        company_name: [
          { required: true, message: "企业名称不可为空", trigger: "blur" },
          {
            min: 3,
            max: 30,
            message: "企业名称长度在3-20个字符间",
            trigger: "blur",
          },
        ],
        company_type: [
          { required: true, message: "请选择企业类型", trigger: "change" },
        ],
        company_size: [
          { required: true, message: "请选择企业规模", trigger: "change" },
        ],
        ArtificialPersonName: [
          { required: true, message: "法人姓名不可为空", trigger: "blur" },
        ],
        TaxpayerIdentityNumber: [
          {
            required: true,
            message: "税号/统一社会信用代码 不可为空",
            trigger: "blur",
          }, 
          {
            required: true,
            validator: validataTaxpayerIdentityNumber,
            trigger: 'blur'
          }
        ],
        createDate: [
          { required: true, message: "请选择日期", trigger: "change" },
        ],
        selectedOptions: [
          { required: true, message: "请选择省/市/区", trigger: "change" },
        ],
        regAddressExtend: [
          { required: true, message: "注册地址不可为空", trigger: "change" },
        ],
        workAddressExtend: [],
        BusinessScope: [
          { required: true, message: "经营范围不可为空", trigger: "blur" },
        ],
        BusinessLicenseUrl: [
          { required: true, message: "营业执照不可为空", trigger: "change" },
        ],
        streetPark: [
          { required: true, message: "请选择街道/园区", trigger:"change" }
        ]
      },
      imguploaddata: {
        Version: "",
        FileTypeID: 8,
        ParamsString: "",
        JZToken: "",
        UserGuid: "",
      }, //上传图片需要的参数

      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,

      step_active: 1, //	步骤条索引

      //flag:0,				//	1发情注册请求，0信息输入失败，默认0
      //registagreement:false,
      // message_tip_title:'提交失败',		//提示信息标题
      // message_tip_content:'还未填写任何信息哦',		//提示信息内容
      // submit_state:'error',		//提交状态
    };
  },
  computed: {},
  components: {
    navTabPart1,
    registFooter,
  },
  created() {
    this.init();
  },
  methods: {
    	//获取验证码
		getcode() {     
      this.iscode = true;
      this.secode = 60;
      this.codetime = setInterval(() => {
        this.secode--;
        if (this.secode <= 0) {
        this.iscode = false;
        clearInterval(this.codetime);
        }
      }, 1000);
		},
    //上传图片成功回调赋值
    uploadfile(val) {
      if(val.status == 201){
        this.$confirm('登录状态失效，请重新登录','提示',{ 
          confirmButtonText:'去登陆',
          cancelButtonText:'过会再去',
          type:'warning'
        })
        .then(() => {
          this.$router.push('/login')
        })
        .catch(() => {})
      }else{
        this.ruleForm_confirmCompany.BusinessLicenseUrl = val.data.split("|")[1];
      }
    },
    handleRemove() {
      this.$refs["upload"].clearFiles();
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    exceedimg() {
      alert("上传图片已超过最大限制(最多上传一张");
    },
    //点击获取省市区 code
    handleChange(val) {
      this.ruleForm_confirmCompany.regAddressID = val[2];
    },
    handleChange1(val) {
      this.ruleForm_confirmCompany.workAddressID = val[2];
    },
    //初始化数据
    init() {
      this.$axios("/API/GetDict", {
        Version: "",
        DictTypeID: "YHXX_QYLX",
      }).then((res) => {
        this.typeList = res.data;
      });
      this.$axios("/API/GetDict", {
        Version: "",
        DictTypeID: "YHXX_QYGM",
      }).then((res) => {
        this.sizeList = res.data;
      });
      this.$axios("/API/GetAddressDictList2", {
        Version: "",
      }).then((res) => {
        this.regionData = res.data;
      });
    },
    //街道/园区输入内容触发搜索请求
    toSearchRList(query){
      if(query !== ''){
        this.searchvalue = query
        this.loading = true
        this.$axios('/API/SearchAreaByName',{
          Version:'',
          AreaName:query,
          PageIndex:this.pageIndex,
          PageSize:this.pageSize,
          JZToken:this.userinfo.TokenCode,
          UserGuid: this.userinfo.UserGuid
        }).then(res => {
          if(res.status == 201){
            this.$confirm('登录状态失效，请重新登录','提示',{ 
              confirmButtonText:'去登陆',
              cancelButtonText:'过会再去',
              type:'warning'
            })
            .then(() => {
              this.$router.push('/login')
            })
            .catch(() => {})
          }
          this.loading = false
          let arr = []
          res.data.forEach(i =>{
            let newobj = {}
            newobj.label = i.ProvinceName+'-'+i.CityName+'-'+i.RegionName+'-'+i.AreaName
            newobj.value = i.AreaCode+','+i.FLnkID
            arr.push(newobj)
          })
          this.streetParkList = arr
        })
      }else{
        this.streetParkList = []
      }
      
    },
    //加载更多数据
    loadmore(){
      this.pageIndex = ++this.pageIndex
      this.$axios('/API/SearchAreaByName',{
        Version:'',
        AreaName:this.searchvalue,
        PageIndex:this.pageIndex,
        PageSize:this.pageSize,
        JZToken:this.userinfo.TokenCode,
        UserGuid: this.userinfo.UserGuid
      }).then(res => {
        let arr = []
        res.data.forEach(i =>{
            let newobj = {}
            newobj.label = i.ProvinceName+'-'+i.CityName+'-'+i.RegionName+'-'+i.AreaName
            newobj.value = i.AreaCode+','+i.FLnkID
            arr.push(newobj)
        })
        this.streetParkList = this.streetParkList.concat(arr)
      })
    },
    //企业认证
    sumbit_CompanyCertification(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.company_certification(
            this.userinfo.TokenCode,
            this.userinfo.UserGuid
          );
        } else {
          console.log("提交失败");
          return false;
        }
      });
    },
    //企业认证
    company_certification(token, guid) {
      let rc = this.ruleForm_confirmCompany;
      let request = {
        Version: "",
        CompanyName: rc.company_name,
        TaxpayerIdentityNumber: rc.TaxpayerIdentityNumber,
        ArtificialPersonName: rc.ArtificialPersonName,
        CompanyTypeID: rc.company_type,
        Regdate: rc.createDate,
        RegAddressID: rc.regAddressID,
        RegAddressExtend: rc.regAddressExtend,
        OfficeAddressID: rc.workAddressID,
        OfficeAddressExtend: rc.workAddressExtend,
        BusinessScope: rc.BusinessLicenseUrl,
        CompanySizeID: rc.company_size,
        BusinessLicenseUrl: rc.BusinessLicenseUrl,
        JZToken: token,
        UserGuid: guid,
      };
      this.$axios("/API/Sumbit_UserAuthentication", request).then((res) => {
        this.step_active += 1
        this.$message("提交成功"); 
      });
    },
    //跳转至登录页面
    login() {
      this.$router.replace("/login");
    },
    //提交注册表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.regist();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //重置注册
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //跳转至首页
    toindex() {
      this.$router.push("/home");
    },
    //调用注册接口
    regist() {
      let requestParams = {
        MobileNo: this.ruleForm.phone,
        PassWord: this.ruleForm.pass,
        DeviceNo: "",
        DeviceManufacturer: "",
        DeviceType: "",
        OsVersion: "",
        PackageName: "",
        IMEI: "",
        ClientType: "",
        ChannelNo: "",
        RecommendUserGuid: "88888888",
        Code: this.ruleForm.code,
        Zone: "206xOe",
      };
      this.$axios("API/UserReg", requestParams).then((res) => {
        if (res.status == 200) {
            //注册成功后请求登录接口获取token,guid
            this.$axios("/API/UserLogin", {
              Version: "",
              MobileNo: this.ruleForm.phone,
              type: 1,
              PassWord: this.ruleForm.pass,
              Zone: "",
              Code: "",
            }).then((s) => {
              this.step_active += 1;
              this.$message.success("注册成功");
              console.log(s);
              if (s.status == 200) {
                this.userinfo = s.data;
                this.imguploaddata.JZToken = s.data.TokenCode
                this.imguploaddata.UserGuid = s.data.UserGuid
              }
            });
        } else if (res.status == 202) {
          alert(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss">
 @media(min-width: 1201px){
    @import "@/pages/login/Register.scss";
 }
 @media(max-width: 1200px){
    @import "@/pages/login/Register_phone.scss";
   
 }
</style>
