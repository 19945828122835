<template>
	<div id="registfooter">
		<div class="mainpage">
			<span @click="toIndex">首页</span>|
			<span @click="toProduct">产品中心</span>|
			<span @click="toNews">新闻动态</span>|
			<span @click="toSolution">解决方案</span>|
			<span @click="toAboutus">关于我们</span>
		</div>
		<div class="copyright">
			<p>
				Copyright ©
				{{ "2020-" + new Date().getFullYear() }}
				南京数字矩阵科技有限公司 版权所有 <a href="http://beian.miit.gov.cn/" >苏ICP备2021012477号-1</a>
			</p>
		</div>
	</div>
</template>

<script>
export default{
	name: 'registFooter',
	data(){ return {} },
	methods:{
		toIndex(){
			this.$router.push('/home')
		},
		toProduct(){
			this.$router.push('/product')
		},
		toNews(){
			this.$router.push('/news')
		},
		toSolution(){
			this.$router.push('/solution')
		},
		toAboutus(){
			this.$router.push('/aboutus')
		}
		
	}
}
</script>

<style lang="scss">
	#registfooter{
		width: 1920px;
		height: 75px;
		position: absolute;
		bottom: 0;
		.mainpage{
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #999999;
			line-height: 26px;
			display: flex;
			justify-content: center;
			span{
				margin: 0 10px;
				&:hover{
					color: #3A86FF;
					text-decoration: underline;
					cursor: pointer;
				}
			}
		}
		.copyright{
			height: 60px;
			display: flex;
			justify-content: center;
			p{
				margin-top: 18px;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #999999;
				line-height: 26px;
				a{
					color: #999;
				}
			}
		}
	}
</style>
