<!--
	Description: 导航栏块1tab
-->
<template>
    <div id="navtab_part">
      <div class="ntPart_top">
        <img
          class="logopc"
          src="~/assets/images/nav/logo_zqt.png"
          alt="找不到资源"
          @click="toindex"
        />
        <img
          class="logophone"
          src="~/assets/images/login/zqt_logo.png"
          alt="找不到资源"
          @click="toindex"
        />
        <span class="gang">|</span>
        <h2>{{pagetitle}}</h2>
        <div class="rightspan"><slot name="topRightText"></slot></div>
      </div>
    </div>
</template>
<script>
export default {
    name:'navTabPart1',
    data(){
        return{}
    },
    props:['pagetitle'],
    created(){},
    methods:{
        //跳转首页
        toindex() {
            this.$router.push("/home");
        }
    }
}
</script>
<style lang="scss" scoped>
    @media(min-width: 1201px){
         #navtab_part{
            .logophone{
                display: none;        
            }
            .ntPart_top {
                height: 70px;
                width: 1200px;
                padding: 0 360px;
                box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
                display: flex;
                align-items: center;
                position: relative;
                img {
                    width: 120px;
                    height: 38px;
                }
                .gang {
                    font-weight: 600;
                    font-size: 16px;
                    margin-left: 20px;
                }
                h2 {
                    margin-left: 18px;
                }
                .rightspan{
                    position: absolute;
                    right: 360px;
                    line-height: 38px;
                    cursor: pointer;
                    text-align: center;

                }
            }
        }
    }
    @media(max-width: 1200px){
        .ntPart_top {
          height: 58px;
          padding: 0 4%;
          box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
          display: flex;
          align-items: center;
          position: relative;
          .logopc{
            display: none;
          }
          img {
            width: 40px;
            width: 40px;
          }
          .gang {
            font-weight: 600;
            font-size: 16px;
            margin-left: 20px;
          }
          h2 {
            font-size: 16px;
            margin-left: 18px;
          }
          .rightspan{
            position: absolute;
            right: 4%;
          }
          
        }
    }
   
</style>